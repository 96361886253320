$black: #000000;
$white: #ffffff;

$primary: #111d43;
$secondary: #ff4100;

$dark-black: #2c3b6a;
$orange: #ffa283;
$blue: #36cce0;
$blue-1: rgba(95, 208, 223, 0.2);
$blue-2: #5fd0df;

$red: #de2828;
$white_gray: #e7e8ec;
$yellow: #ffc90a;
$yellow_1: #ffd84f;
$gray: #c9cdd5;
$gray_1: #70778e;
$gray_2: #bbc0d1;
$gray_3: #ffede6;
$gray_4: #c3cbd4;
$gray_5: #626d7c;
$gray_6: #fce7e7;
$gray_7: #e6e7e8;
$gray_8: #f7f7f7;
$gray_9: #c9cdd4;
$green: #39b54a;
$green_1: #ecf8ed;
$orange: #f79c15;
$lemon_yellow: #cbe141;
$white_gray_1: #fafafb;
$reject_gray: #dfeee2;
$error: #d32f2f;
